
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ContactDialog extends Vue {
  public contacts: any = [
    {
      avatar: require("../../assets/contacts/Nancy.webp"),
      title: "contactDialog.head",
      subtitle: "Nancy Ulbricht",
      email: "vorsitzender@bv57nsdl.de",
    },
    {
      avatar: require("../../assets/contacts/Peter.webp"),
      title: "contactDialog.substitute",
      subtitle: "Peter Denke",
      email: "stellvertreter@bv57nsdl.de",
    },
    {
      avatar: require("../../assets/contacts/Jens.webp"),
      title: "contactDialog.treasurer",
      subtitle: "Jens Vollrath",
      email: "kassenwart@bv57nsdl.de",
    },
    {
      avatar: require("../../assets/contacts/David.webp"),
      title: "contactDialog.trainer",
      subtitle: "David Breitenborn",
      email: "david.breitenborn@bv57nsdl.de",
    },
    {
      avatar: require("../../assets/contacts/Nico.webp"),
      title: "contactDialog.trainer",
      subtitle: "Nico Dannowski",
      email: "jugendwart@bv57nsdl.de",
    },
  ];

  public mounted() {}

  public closeDialog() {
    this.$emit("closeOverlay");
  }
}
